"use client"

import React from "react"
import { usePathname, useSearchParams } from "next/navigation"
import { usePostHog } from "posthog-js/react"

export default function PostHogPageView(): JSX.Element {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const posthog = usePostHog()

  React.useEffect(() => {
    if (pathname) {
      let url = window.origin + pathname
      if (searchParams && searchParams.toString()) {
        url = url + `?${searchParams.toString()}`
      }
      posthog?.capture("$pageview", {
        $current_url: url,
      })
    }
  }, [pathname, searchParams, posthog])

  return <></>
}
