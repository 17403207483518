"use client"

import { useEffect, useState } from "react"
import { usePathname } from "next/navigation"
import Script from "next/script"

import { env } from "@/env.mjs"
import * as pixel from "@/lib/meta-pixel"

export const FacebookPixel = () => {
  const [loaded, setLoaded] = useState(false)
  const pathname = usePathname()

  useEffect(() => {
    if (!loaded) return

    pixel.pageview()
  }, [pathname, loaded])

  return env.NEXT_PUBLIC_APP_URL === "https://www.wasii.pe" ? (
    <div>
      {/* {localStorage.getItem("acceptedCookies") === "true" ? */}
      <Script
        id={pixel.FB_PIXEL_ID}
        src="/scripts/pixel.js"
        strategy="afterInteractive"
        onLoad={() => {
          console.log("Facebook Pixel loaded")
          setLoaded(true)
        }}
        data-pixel-id={pixel.FB_PIXEL_ID}
      />
      {/* : null} */}
    </div>
  ) : null
}
