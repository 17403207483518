"use client"

import React from "react"
import posthog from "posthog-js"
import { PostHogProvider as PHProvider } from "posthog-js/react"

import { env } from "@/env.mjs"

if (
  typeof window !== "undefined" &&
  env.NEXT_PUBLIC_NODE_ENV !== "development"
) {
  posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: `${env.NEXT_PUBLIC_APP_URL}/ingest`,
    ui_host: "https://eu.posthog.com",
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
    persistence: "localStorage+cookie",
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug() // comment the following to disable console logs
    },
  })
}

export function PostHogProvider({ children }: { children: React.ReactNode }) {
  return <PHProvider client={posthog}>{children}</PHProvider>
}
