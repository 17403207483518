import { create } from "zustand"
import { devtools } from "zustand/middleware"

type AsPathStoreType = {
  prevAsPath: string | undefined
  currentAsPath: string | undefined
  setState: (state: AsPathStoreType) => void
}

const useAsPathStore = create<AsPathStoreType>()(
  devtools(
    (set) => ({
      prevAsPath: undefined,
      currentAsPath: undefined,
      setState: (state) => set(state),
    }),
    { name: "as-path-store" }
  )
)

export { useAsPathStore }
