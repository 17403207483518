"use client"

import * as React from "react"
import { usePathname, useRouter } from "next/navigation"
import { ModalProvider } from "@/context/modal-context"
import { useAsPathStore } from "@/store/as-path-store"
import { useAuth, useUser } from "@clerk/nextjs"
import { Libraries, useJsApiLoader } from "@react-google-maps/api"
import { AppProgressBar as ProgressBar } from "next-nprogress-bar"
import { ThemeProvider as NextThemesProvider } from "next-themes"
import { type ThemeProviderProps } from "next-themes/dist/types"
import { usePostHog } from "posthog-js/react"

import { env } from "@/env.mjs"
import { useMediaQuery } from "@/hooks/use-media-query"
import { useMounted } from "@/hooks/use-mounted"
import { TooltipProvider } from "@/components/ui/tooltip"

const libraries: Libraries = ["places"]

export function Providers({ children }: ThemeProviderProps) {
  const hasMounted = useMounted()
  const { isSignedIn, userId } = useAuth()
  const { user } = useUser()
  const posthog = usePostHog()
  const isLG = useMediaQuery("(min-width: 1024px)")
  const pathname = usePathname()
  const router = useRouter()
  const { currentAsPath } = useAsPathStore()

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    libraries,
  })

  // Identify user in PostHog
  React.useEffect(() => {
    if (
      isSignedIn &&
      userId &&
      user &&
      localStorage.getItem("acceptedCookies") === "true" &&
      !posthog._isIdentified()
    ) {
      posthog?.identify(user.id, {
        email: user.emailAddresses.find(
          (e) => user.primaryEmailAddressId === e.id
        )?.emailAddress,
        membershipPlan: user.publicMetadata.membershipPlan ?? "sin-especificar",
        username: `${user.firstName} ${user.lastName}`,
      })
    }

    if (isSignedIn) {
      const loginAttempProvider = localStorage.getItem("loginAttempProvider")
      loginAttempProvider &&
        localStorage.setItem("lastLoginProvider", loginAttempProvider)
    } else {
      localStorage.removeItem("loginAttempProvider")
    }

    if (!isSignedIn && posthog._isIdentified()) {
      posthog.reset()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posthog, user])

  // Track pathname changes for back buttons
  React.useEffect(() => {
    if (currentAsPath !== pathname) {
      useAsPathStore.setState((state) => ({
        ...state,
        currentAsPath: pathname,
        prevAsPath: currentAsPath,
      }))
    }
  }, [pathname, currentAsPath])

  React.useEffect(() => {
    if (
      user?.publicMetadata?.membershipPlan === "enterprise" &&
      user?.publicMetadata?.onboardingStatus !== "completed" &&
      pathname !== "/onboarding/empresarial"
    ) {
      router.push("/onboarding/empresarial")
    }
  }, [user, pathname, router])

  if (!hasMounted || !isLoaded) return null

  return (
    <NextThemesProvider
      attribute="class"
      defaultTheme="light"
      enableSystem
      disableTransitionOnChange
    >
      <TooltipProvider>
        <ModalProvider>
          {children}
          <ProgressBar
            height="4px"
            color="#681EB0"
            options={{ showSpinner: isLG }}
            shallowRouting
          />
        </ModalProvider>
      </TooltipProvider>
    </NextThemesProvider>
  )
}
