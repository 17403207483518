"use client"

import { useEffect, useState } from "react"
import Link from "next/link"

import { useMounted } from "@/hooks/use-mounted"
import { Button } from "@/components/ui/button"

export function CookiesDialog() {
  const [showBanner, setShowBanner] = useState(false)
  const mounted = useMounted()

  const onAccept = (): void => {
    localStorage.setItem("acceptedCookies", "true")
    // posthog.opt_in_capturing({})
    setShowBanner(false)
  }

  const onReject = (): void => {
    localStorage.setItem("acceptedCookies", "false")
    // posthog.opt_out_capturing()
    setShowBanner(false)
  }

  useEffect(() => {
    if (!localStorage.getItem("acceptedCookies")) {
      setShowBanner(true)
    }
  }, [])
  if (!mounted) return null

  return localStorage.getItem("acceptedCookies") === "true" ||
    localStorage.getItem("acceptedCookies") === "false" ? null : showBanner ? (
    <div className="fixed bottom-0 z-50 w-screen bg-white p-8 shadow-[0px_-4px_11px_0px_rgba(0,0,0,0.25)]">
      <div className="mx-auto flex w-full max-w-7xl flex-col items-center gap-4 md:flex-row md:gap-20">
        <p>
          En este sitio empleamos y recolectamos cookies con el fin de
          garantizar un acceso seguro y brindar contenido personalizado según
          tus intereses. Al seguir explorando el sitio, nos otorgas autorización
          para su empleo. Consulta nuestra sección de{" "}
          <Link
            href="/politica-de-cookies"
            target="_blank"
            className="cursor-pointer underline"
          >
            Política de Cookies
          </Link>{" "}
          para más detalles.
        </p>
        <div className="flex items-center gap-4 sm:gap-8">
          <Button
            size="lg"
            onClick={onReject}
            className="text-md self-end md:self-auto"
            variant="outline"
          >
            Rechazar
          </Button>
          <Button
            size="lg"
            onClick={onAccept}
            className="text-md self-end md:self-auto"
          >
            Aceptar
          </Button>
        </div>
      </div>
    </div>
  ) : null
}
