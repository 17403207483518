export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID

// METHODS
export const pageview = () => {
  window.fbq("track", "PageView")
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: string, options = {}) => {
  window.fbq("track", name, options)
}

// TYPES
export type PixelEventCustomData =
  | facebook.Pixel.ViewContentParameters
  | facebook.Pixel.SearchParameters
  | facebook.Pixel.AddToCartParameters
  | facebook.Pixel.AddToWishlistParameters
  | facebook.Pixel.InitiateCheckoutParameters
  | facebook.Pixel.AddPaymentInfoParameters
  | facebook.Pixel.PurchaseParameters
  | facebook.Pixel.LeadParameters
  | facebook.Pixel.CompleteRegistrationParameters
  | facebook.Pixel.CustomParameters

export type PixelEventName =
  | "AddToWishlist"
  | "Purchase"
  | "Lead"
  | "CompleteRegistration"
  | "AddPaymentInfo"
  | "AddToCart"
  | "InitiateCheckout"
  | "Search"
  | "ViewContent"
  | "Contact"
  | "CustomizeProduct"
  | "Donate"
  | "FindLocation"
  | "Schedule"
  | "StartTrial"
  | "SubmitApplication"
  | "Subscribe"
  | "ViewContent"
  | "ViewProduct"
  | "Other"
